<template>
  <div class="index-wrap">
    <div class="head-box" :style="{'background-image':`url(${top_bg})`}">
      <img class="logo" src="../assets/logo.png" alt="" srcset="" />
      <div class="nav-box">
        <div class="nav-list" @click="topScroll(0)">
          首页
          <img
            src="../assets/icon_selected.png"
            :style="{ opacity: check == 0 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(1)">
          我们的优势
          <img
            src="../assets/icon_selected.png"
            :style="{ opacity: check == 1 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(2)">
          省钱攻略
          <img
            src="../assets/icon_selected.png"
            :style="{ opacity: check == 2 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(3)">
          关于我们
          <img
            src="../assets/icon_selected.png"
            :style="{ opacity: check == 3 ? 1 : 0 }"
          />
        </div>
      </div>
    </div>
    <div>
      <img class="banner" src="../assets/jhtqb_banner.png" alt="" srcset="" />
    </div>
    <div class="center">
      <img class="pic_6" src="../assets/jhtqb_pic_6.png" alt="" srcset="" />
    </div>
    <div class="center">
      <img class="pic_7" src="../assets/jhtqb_pic_7.png" alt="" srcset="" />
    </div>
    <div class="center" ref="pic8">
      <img class="pic_8" src="../assets/jhtqb_pic_8.png" alt="" srcset="" />
    </div>
    <div class="banner-wrap">
      <div class="banner-title center">
        <img
          class="pic_5"
          ref="pic5"
          src="../assets/pic_5.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="banner-nav center">
        <div
          class="b-n-l"
          :class="{ active: bannerIndex === 0 }"
          @click="bannerIndex = 0"
        >
          淘宝/天猫省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 1 }"
          @click="bannerIndex = 1"
        >
          京东省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 2 }"
          @click="bannerIndex = 2"
        >
          外卖省钱
        </div>
      </div>
      <div class="banner-box">
        <div class="next-pre">
          <img
            class="icon_last"
            @click="handlePre"
            src="../assets/icon_last.png"
            alt=""
            srcset=""
          />
          <img
            class="icon_next"
            @click="handleNext"
            src="../assets/icon_next.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 0">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx0})` }"
          >
            <img src="../assets/tb_step_1.png" alt="" srcset="" />
            <img src="../assets/tb_step_2.png" alt="" srcset="" />
            <img src="../assets/tb_step_3.png" alt="" srcset="" />
            <img src="../assets/tb_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 1">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx1})` }"
          >
            <img src="../assets/jd_step_1.png" alt="" srcset="" />
            <img src="../assets/jd_step_2.png" alt="" srcset="" />
            <img src="../assets/jd_step_3.png" alt="" srcset="" />
            <img src="../assets/jd_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 2">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx2})` }"
          >
            <img src="../assets/wm_step_1.png" alt="" srcset="" />
            <img src="../assets/wm_step_2.png" alt="" srcset="" />
            <img src="../assets/wm_step_3.png" alt="" srcset="" />
            <img src="../assets/wm_step_4.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer" ref="footer">
      <img class="pic" src="../assets/pic.png" alt="" srcset="" />
      <div class="des-box">
        <p class="footer-title">关于我们</p>
        <p>·商务合作/问题咨询</p>
        <p>·在线客服 如您遇到任何疑问，欢迎联系咨询</p>
        <p>·客服电话：028-63208381</p>
        <p>·服务时间：9:00-18:00</p>
        <p class="icp">
          ·
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #fff"
            target="_blank"
            rel="noopener noreferrer"
            >蜀ICP备2022018105号</a
          >
            &nbsp;&nbsp;成都优航信息科技有限公司
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import top_bg from '@/assets/top_bg.png'
export default {
  data() {
    return {
      activeIndex: 0,
      bannerIndex: 0,
      leftPx0: "0",
      leftPx1: "0",
      leftPx2: "0",
      topPic8: 0,
      topPic5: 0,
      topFooter: 0,
      check: 0,
      top_bg
    };
  },
  methods: {
    handlePre() {
      this["leftPx" + this.bannerIndex] = "0";
    },
    handleNext() {
      this["leftPx" + this.bannerIndex] = "-385px";
    },
    topScroll(i) {
      let top = 0;
      this.check = i;
      switch (i) {
        case 0:
          top = 0;

          break;
        case 1:
          top = this.topPic8;
          break;
        case 2:
          top = this.topPic5;
          break;
        case 3:
          top = this.topFooter;
          break;
      }
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    scleTop() {
      this.topPic8 = this.$refs.pic8.offsetTop - 87;
      this.topPic5 = this.$refs.pic5.offsetTop - 87;
      this.topFooter = this.$refs.footer.offsetTop - 87;
    },
  },
  mounted() {
    setTimeout(() => {
      this.scleTop();
    }, 200);
    window.onresize = () => {
      this.scleTop();
    };
  },
};
</script>

<style lang="scss">
.index-wrap {
  padding-top: 87px;
}
.head-box {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  align-items: center;
  height: 87px;
  background: linear-gradient(180deg, #f99f59 0%, #ff0000 100%);
  padding: 0 400px;
  .logo {
    width: 166px;
    height: 50px;
  }
  .nav-box {
    display: flex;
    align-items: center;
    .nav-list {
      font-size: 24px;
      color: #fff;
      margin-left: 91px;
      cursor: pointer;
      white-space: nowrap;
      img {
        margin: 5px auto 0;
        display: block;
        width: 38px;
        height: 6px;
      }
    }
  }
}
.center {
  text-align: center;
}
.banner,
.pic_7 {
  width: 100%;
}
.pic_6 {
  width: 996px;
  margin-top: 34px;
}
.pic_7 {
  margin-top: 45px;
}
.pic_8 {
  width: 1140px;
  margin-top: 36px;
}
.banner-wrap {
  .banner-title {
    margin-top: 118px;
  }
  .banner-nav {
    width: 1140px;
    margin: 43px auto 0;
    display: flex;
    align-items: center;
    .b-n-l {
      flex: 1;
      text-align: center;
      color: #f64847;
      font-size: 24px;
      cursor: pointer;
    }
    .active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 137px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        height: 3px;
        background: linear-gradient(270deg, #ff6f6f 0%, #f64847 100%);
        border-radius: 2px;
      }
    }
    .b-n-l-4 {
      &:after {
        width: 78px;
      }
    }
  }
  .banner-box {
    width: 1140px;
    margin: 28px auto 0;
    .banner-ls-box-h {
      width: 100%;
      overflow: hidden;
    }
    .banner-ls-box {
      display: flex;
      transition: 0.3s;
      img {
        width: 360px;
        height: 428px;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .next-pre {
      width: 100%;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        width: 74px;
        height: 74px;
        top: 155px;
        cursor: pointer;
      }
      .icon_last {
        left: -37px;
      }
      .icon_next {
        right: -37px;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 111px;
  background: linear-gradient(180deg, #fcb055 0%, #f67d40 100%);
  height: 462px;
  padding: 0 435px 0 443px;
  width: 100%;
  .pic {
    width: 376px;
    height: 429px;
  }
  .des-box {
    width: 595px;
    .footer-title {
      font-size: 47px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    p {
      font-size: 28px;
      color: #ffffff;
      line-height: 55px;
      white-space: nowrap;
    }
    .icp {
      font-size: 23px;
    }
  }
}
</style>
